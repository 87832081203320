var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flatPlan"},[_c('carousel',{attrs:{"touchDrag":false,"mouseDrag":false,"paginationEnabled":false,"centerMode":false,"perPageCustom":[
      [1920, 4],
      [1440, 3],
      [1024, 3],
      [768, 2],
      [425, 1],
      [375, 1],
      [320, 1],
    ],"navigationEnabled":true}},_vm._l((_vm.flats),function(item,i){return _c('slide',{key:i},[_c('div',{staticClass:"flatCard"},[(_vm.floor3d === 1)?_c('img',{staticClass:"flat__img",attrs:{"src":item.img,"alt":""}}):_vm._e(),(_vm.floor3d === 2)?_c('img',{staticClass:"flat__img",attrs:{"src":item.imgTwo,"alt":""}}):_vm._e(),(item.countFloor === 2)?_c('div',{staticClass:"card_row"},[_c('v-btn',{staticClass:"card_row-btn",class:{ active: _vm.firstFloor3d },on:{"click":function($event){_vm.firstFloor3d = true;
              _vm.secondFloor3d = false;
              _vm.floor3d = 1;}}},[_vm._v("1 поверх")]),_c('v-btn',{staticClass:"card_row-btn",class:{ active: _vm.secondFloor3d },on:{"click":function($event){_vm.firstFloor3d = false;
              _vm.secondFloor3d = true;
              _vm.floor3d = 2;}}},[_vm._v("2 поверх")])],1):_vm._e(),_c('div',{staticClass:"flat__info"},[_c('p',{staticClass:"flat__size"},[_vm._v(_vm._s(item.size))]),_c('div',{staticClass:"flat__info-more"},[_c('span',[_vm._v(_vm._s(item.floor))]),_c('span',[_vm._v(_vm._s(item.section))])])])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }