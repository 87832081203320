var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flatPlan"},[_c('carousel',{staticClass:"carousel",attrs:{"touchDrag":false,"mouseDrag":false,"paginationEnabled":false,"centerMode":true,"perPageCustom":[
      [1920, 4],
      [1440,  3],
      [1024, 3],
      [768, 2],
      [425, 1],
      [375, 1],
      [320, 1],
    ],"navigationEnabled":true}},_vm._l((_vm.flats),function(item,i){return _c('slide',{key:i},[_c('div',{staticClass:"flatCard"},[_c('img',{staticClass:"flat__img",attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"flat__info"},[_c('p',{staticClass:"flat__size"},[_vm._v(_vm._s(item.size))]),_c('div',{staticClass:"flat__info-more"},[_c('span',[_vm._v(_vm._s(item.floor))]),_c('span',[_vm._v(_vm._s(item.section))])])])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }